<template>
  <div class="Share">
    <div class="top-title">
      <div class="top-text">分享充值</div>
      
    </div>
        <el-form ref="form" label-width="100px">
          <div class="top-box">
              <div class="top-my">
                  分享佣金充值:&nbsp;&nbsp;&nbsp;5000元
              </div>
              <el-button
                type="primary"
                class="biy-icon" icon="el-icon-check" @click="upgrade()"
                >充值</el-button>
            </div>
        </el-form>
    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" style="width:90%" row-class-name="tableRowStyle" v-loading="loading">
        <el-table-column prop="serialNumber" label="订单编号"> </el-table-column>
        <el-table-column prop="membership" label="会员姓名"> </el-table-column>
        <el-table-column prop="recruitment" label="招聘方"> </el-table-column>
        <el-table-column prop="orderTime" label="日期"> </el-table-column>
        <el-table-column prop="toSharThe" label="分享所得佣金"> </el-table-column>
      </el-table>
            <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// 假数据
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"

export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,


      // 数据
      list:[],
    };
  },
  created() {
     this.getSong();
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
				let res = await apiurl.deptlist({
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        })
            if(typeof(res.ErrMsg)=="undefined"){
                    alert("接口调用失败");
                }else if(res.ErrMsg){
                    alert("接口调用失败:"+res.ErrMsg);                    
             }else{
                this.list = fakelist.Share.rows
                this.total = fakelist.Share.total
                this.loading = false
          }
      },

    // 升级成经理
    upgrade(){
       
    },


    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },

  },
  computed: {},
};
</script>


<style  scoped>
 .top-box{
   width: 98%;
   display: flex;
   justify-content: center;
    padding: 0px;
}
.top-box .top-my{
  width: 200px;
  line-height: 40px;
  font-size: 16px;
 
}
 .biy-icon{
  width: 100px;
  height: 40px;
  border-radius: 4px;
  background-color: #01847F;
}

@import "../../assets/css/liststyle.css";
</style>